import {FedopsInteractions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/enums/fedopsInteractions';
import {mapUserInputSelectionMapToChoices} from '../../mappers/mapUserInputSelectionMapToChoices';
import {RootStore} from '../../stores/RootStore';
import {getAppErrorHandler} from './getAppErrorHandler';
import {getCtaButtonClickHandler} from './getCtaButtonClickHandler';
import {WidgetEventHandler} from './types';
import {AfterAddToCartChoices, CtaButtonsWidgetComponentIds} from '../../../ctaButtonsWidget/config/constants';
import {mapUserInputTextToFields} from '../../mappers/mapUserInputTextToFields';
import {action} from 'mobx';
import {SPECS} from '../../../../specs';
import {createCheckout} from '../createCheckout';
import {interruptAction} from './interruptAction';
import {TrackEventName} from '@wix/wixstores-client-core';
import {publishInitiateCheckoutTrackEvent} from '../product-service/publishInitiateCheckoutTrackEvent';

const publishAddToCartTrackEvent = (rootStore: RootStore) => {
  return rootStore.wixCodeApi.window.trackEvent(TrackEventName.ADD_TO_CART, {...rootStore.$state.trackParams} as any);
};

const onAddToCartCompleted = (rootStore: RootStore, isPreOrder) => (): Promise<void> => {
  const flowAPI = rootStore.controllerParams.flowAPI;
  const isMS3 = flowAPI.experiments.enabled(SPECS.PRODUCT_PAGE_BLOCKS_MS3);
  const ShouldOpenSideCart = flowAPI.experiments.enabled(SPECS.ShouldOpenSideCart);
  const isMobile = flowAPI.environment.isMobile;
  const settings = rootStore.services.components.ctaButtonsWidget.settings;
  const afterAddToCartDesktop = settings?.afterAddToCart;
  const afterAddToCartMobile = settings?.afterAddToCartMobile ?? afterAddToCartDesktop;
  const addToCartAction = isMobile ? afterAddToCartMobile : afterAddToCartDesktop;
  const afterCompletionAction = isPreOrder ? settings?.afterPreOrder : addToCartAction;
  const selectedAction = afterCompletionAction || AfterAddToCartChoices.MINI_CART;
  const buttonComponentId =
    isMS3 && isPreOrder ? CtaButtonsWidgetComponentIds.PreOrderButton : CtaButtonsWidgetComponentIds.AddToCartBtn;

  if (selectedAction === AfterAddToCartChoices.CHECKOUT) {
    return createCheckout(rootStore, {withNavigateToCheckout: true, isPreOrder})
      .then((checkout) => publishInitiateCheckoutTrackEvent(rootStore, checkout.checkoutId as string))
      .then(() => Promise.resolve());
  }

  publishAddToCartTrackEvent(rootStore);
  switch (selectedAction) {
    case AfterAddToCartChoices.CART:
      return rootStore.wixStoresApi.navigate.toCart();

    case AfterAddToCartChoices.MINI_CART:
      if (ShouldOpenSideCart) {
        try {
          return Promise.resolve(rootStore.wixStoresApi.cart.openSideCart());
        } catch {
          return rootStore.wixStoresApi.navigate.toCart();
        }
      } else {
        if (isMobile) {
          return rootStore.wixStoresApi.navigate.toCart();
        }
        return Promise.resolve(rootStore.wixStoresApi.cart.showMinicart());
      }
    case AfterAddToCartChoices.TINY_CART:
      if (isMobile) {
        return rootStore.wixStoresApi.navigate.toCart();
      }
      return Promise.resolve(rootStore.wixStoresApi.cart.showTinycart());
    case AfterAddToCartChoices.NONE:
      rootStore.$state.isAddToCartInProgress = false;
      return rootStore.services.components.ctaButtonsWidget.indicateAddToCartCompleted(buttonComponentId);
    default:
      return Promise.resolve();
  }
};

const handleAddToCart = async (rootStore: RootStore): Promise<void> => {
  rootStore.$state.isAddToCartInProgress = true;
  const productId = rootStore.$state.product.id;
  const quantity = rootStore.$state.quantityInput;
  const preOrderRequested = rootStore.$state.isPreOrder;
  const customTextFields = mapUserInputTextToFields(rootStore);
  const choices = mapUserInputSelectionMapToChoices(rootStore);
  const addProductsParams = [{productId, quantity, preOrderRequested, options: {customTextFields, choices}}];
  const errorHandler = getAppErrorHandler(rootStore);
  const onAddToCartCompletedHandler = onAddToCartCompleted(rootStore, preOrderRequested);
  return rootStore.wixStoresApi.cart
    .addProducts(addProductsParams)
    .then(onAddToCartCompletedHandler)
    .catch(errorHandler)
    .finally(action(() => (rootStore.$state.isAddToCartInProgress = false)));
};

export const handleAddToCartClick = (rootStore: RootStore): WidgetEventHandler<any> => {
  return getCtaButtonClickHandler(rootStore, async () => {
    const addToCartAction = () =>
      rootStore.reportFedopsInteraction(FedopsInteractions.AddToCart, () => handleAddToCart(rootStore));

    return interruptAction(rootStore.wixCodeService.onAddToCartInterruption, addToCartAction);
  });
};
